const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' }
]

const maybeParseDate =  (date:any) => {
	return typeof date == 'string' ? new Date(Date.parse(date)) : date;
}

export const relativeDate = (date:any) => {
    const rtf = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" });

    let duration = (maybeParseDate(date) - Date.now()) / 1000;

    for (let i = 0; i < DIVISIONS.length; i++) {
        const division = DIVISIONS[i];

        if (Math.abs(duration) < division.amount) {
            return rtf.format(Math.round(duration), division.name as any)
        }
        
        duration /= division.amount
    }
}

export const formatDate = (date: string) => maybeParseDate(date).toLocaleDateString()

export const IOSFormatDate = (str:string) => {
    const localeDate = new Date(str).toLocaleDateString('ru', { month: 'short', day: 'numeric' })

    const parts = localeDate.split(' ');

    if (parts[1].length > 2) {
        parts[1] = parts[1].substr(0, 3);
    }

    return parts.join(' ');
}